import request from '@/utils/request'
const baseURL = '/API/Remark'

export const GetData = function (data) {// 网站问题反馈
    return request({
        url: "/API/Remark/GetData",
        method: 'GET',
        params: data
    })
}

export const GetListByCardId = function (data) {// 网站问题反馈
    return request({
        url: "/API/Remark/GetListByCardId",
        method: 'GET',
        params: data
    })
}

export const GetListByModelId = function (data) {// 网站问题反馈
    return request({
        url: "/API/Remark/GetListByModelId",
        method: 'GET',
        params: data
    })
}