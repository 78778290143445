import request from '@/utils/request'
const baseURL = '/API/User/Card'

export const GetAllListByModelId = function (data) {
    return request({
        method: 'GET',
        url: '/API/User/Card/GetAllListByModelId',
        params: data,
    })
}
export const GetData = function (data) {
    return request({
        method: 'GET',
        url: '/API/User/Card/GetData',
        params: data,
    })
}

export const GetExamListByModelId = function (data) {
    return request({
        method: 'GET',
        url: '/API/User/Card/GetExamListByModelId',
        params: data,
    })
}
export const GetKnowledgeListByModelId = function (data) {
    return request({
        method: 'GET',
        url: '/API/User/Card/GetKnowledgeListByModelId',
        params: data,
    })
}
export const GetNoteListByModelId = function (data) {
    return request({
        method: 'GET',
        url: '/API/User/Card/GetNoteListByModelId',
        params: data,
    })
}
export const GetOtherExamByModelId = function (data) { //获取某个模型的相关考点 卡片列表
    return request({
        method: 'GET',
        url: '/API/User/Card/GetOtherExamByModelId',
        params: data,
    })
}
export const GetUserNoteListByModelId = function (data) { //根据模型id获取用户笔记列表
    return request({
        method: 'GET',
        url: '/API/User/Card/GetUserNoteListByModelId',
        params: data,
    })
}
export const GetUserNoteListByCardId = function (data) { //根据卡片id获取用户笔记列表
    return request({
        method: 'GET',
        url: '/API/User/Card/GetUserNoteListByCardId',
        params: data,
    })
}

export const AddNote = function (data) { //添加\修改  笔记
    return request({
        url: '/API/User/Card/AddNote',
        method: 'POST',
        data:{
            json:data
        },
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const DelNote = function (data) { //删除 笔记
    return request({
        url: '/API/User/Card/DelNote',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const AddFeedback = function (data) { //添加\修改  反馈
    return request({
        url: '/API/User/Card/AddFeedback',
        method: 'POST',
        data:{
            json:data
        },
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const DelFeedback = function (data) { //删除 反馈
    return request({
        url: '/API/User/Card/DelFeedback',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const GetUserFeedbackListByModelId = function (data) { //根据模型id获取用户反馈列表
    return request({
        url: '/API/User/Card/GetUserFeedbackListByModelId',
        method: 'GET',
        params: data
    })
}
export const GetUserFeedbackListByCardId = function (data) {//根据卡片id获取用户反馈列表
    return request({
        url: '/API/User/Card/GetUserFeedbackListByCardId',
        method: 'GET',
        params: data
    })
}