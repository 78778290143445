<template>
    <a-modal :dialog-style="{ top: positon.y + 'px', left: positon.x + 'px' }" :visible="dialogVisable" :footer="null"
        :mask="false" @cancel="close">
        <a-spin :spinning="loading">
            <div class="pop_inner">
                <div class="title">{{ info.Title }} </div>
                <div class="source" v-if="info.Source">来源：{{ info.Source }}</div>
                <div class="tags">
                    <template v-for="(item, index) in tags">
                        <a-tag :key="index" color="#f57d32" v-if="item">{{ item }}</a-tag>
                    </template>
                </div>
                <div class="content">{{ info.Content }}</div>
            </div>
        </a-spin>

    </a-modal>
</template>
<script>
import { GetData } from '@/api/remark'
export default {
    // props:['visable','data', 'positon'],
    props: {
        visable: Boolean,
        data: Object,
        positon: {
            type: Object,
            default: () => {
                return {
                    x: 0,
                    y: 0
                }
            }
        },
        id: [String, Number]
    },
    data() {
        return {
            dialogVisable: false,
            loading: false,
            info: {
                Title: '',
                Tags: '',
                Source: '',
                Content: ''
            },
        }
    },
    computed: {
        tags() {
            this.info.Tags = this.info.Tags || ''
            return this.info.Tags.split(',')
        }
    },
    watch: {
        visable(val) {
            this.dialogVisable = val
        },
        positon(val) {
            console.log(val);
        },
        id(val) {
            this.info = {
                Title: '',
                Tags: '',
                Source: '',
                Content: ''
            },
                this.getData(val)
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        async getData(id) {
            this.loading = true
            await GetData({ id: id }).then(res => {
                const { data } = res
                this.info = data
            }).catch(e => e)
            this.loading = false
        }
    },
}
</script>
<style scoped lang="less">
.pop_inner {
    width: 400PX;

    .title {
        font-size: 20px;
        line-height: 1.167;
        color: #001529;
        padding: 0.06rem 0;
        margin-bottom: 0.1rem;
    }

    .source {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 10px;
    }

    .content {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 10px;
    }

    .tags {
        margin-bottom: 10px;

        :deep(.a-tag) {
            margin-bottom: 5px;
        }
    }
}

:deep(.ant-modal-content) {
    border-radius: 4px;
    position: relative;
    overflow: visible;

    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 8.48528137px;
        height: 8.48528137px;
        background: transparent;
        border-style: solid;
        border-width: 4.24264069px;
        transform: rotate(-135deg);
        border-color: transparent #fff #fff transparent;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, .07);
        top: -3.2px;
        left: 20px;
    }
}

:deep(.ant-modal) {
    transform-origin: 0 0 !important;
    margin: 0;
}
</style>