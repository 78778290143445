<template>
    <div class="editor" @click="editorClick($event)" ref="editor" contenteditable="true" v-viewer.rebuild @blur="closePop">
        <div v-html="content" @mousedown.stop="mouseDown($event)" @mouseup.stop="mouseUp($event)"
            v-if="content && isEditable" contenteditable="false" :id="'test' + id"></div>
        <div v-html="content" v-if="content && !isEditable" contenteditable="false"></div>
        <!-- <div @mousedown.stop="mouseDown($event)" @mouseup="mouseUp($event)">
                <div><Editor :value="content" /></div>
            </div> -->

        <div class="popover_txt" :style="'left:' + position.x + 'px; top:' + position.y + 'px;'" v-show="popVisiable">
            <div class="ant-popover-arrow"></div>
            <div class="btn">
                <a-button @click.stop="addNote">个人笔记</a-button>
                <a-button @click.stop="addFeedback">内容反馈</a-button>
            </div>
        </div>
    </div>
</template>
<script>
import Editor from './Editor.vue'
import $ from 'jquery'
export default {
    props: {
        data: String,
        isEditable: {
            type: Boolean,
            default: false,
        },
        id: [String, Number],
        keyword: String,
        selectItem: {
            type: Object,
            default: {},
        },
    },
    components: {
        Editor,
    },
    data(vm) {
        return {
            content: '',
            editor: null,
            downPos: {
                x: '',
                y: '',
            },
            upPos: {
                x: '',
                y: '',
            },
            selection: '',
            popVisiable: false,
            startIndex: 0,
            endIndex: 0,
            timer: null,
        }
    },
    methods: {
        editorClick(e) {
            if (e.target.className == 'detail-btn') {
                const id = e.target.attributes['data-id'].nodeValue
                this.$emit('remark', { e, id })
            }
        },
        getStartIndex(len, level, el) {
            if (window.getSelection().toString() == '') return
            if (level == 0) {
                len = window.getSelection().anchorOffset
                el = window.getSelection().anchorNode
                if (el.parentElement.attributes?.length > 0) {
                    var reg = /<[^<>]+>/g
                    var list = el.parentElement.outerHTML.match(reg)
                    len += list[0].length
                    el = el.parentElement
                }
            }
            var back = this.getSiblingEL(len, el)
            if (back.el.parentNode.id !== 'test' + this.id) {
                this.getStartIndex(back.len, level + 1, back.el.parentNode)
            } else {
                this.startIndex = back.len
                return back
            }
        },
        //取结束下标
        getEndIndex(len, level, el) {
            if (window.getSelection().toString() == '') return
            if (level == 0) {
                len = window.getSelection().focusOffset
                el = window.getSelection().focusNode
                if (el.parentElement.attributes?.length > 0) {
                    var reg = /<[^<>]+>/g
                    var list = el.parentElement.outerHTML.match(reg)
                    len += list[0].length
                    el = el.parentElement
                }
            }
            var back = this.getSiblingEL(len, el)
            if (back.el.parentNode.id != 'test' + this.id) {
                this.getEndIndex(back.len, level + 1, back.el.parentNode)
            } else {
                this.endIndex = back.len
                return 123
            }
        },
        //遍历同级的元素
        getSiblingEL(len, el) {
            while (1) {
                if (el.previousSibling != null)
                    len +=
                        el.previousSibling.outerHTML === undefined
                            ? el.previousSibling.length
                            : el.previousSibling.outerHTML.length
                else {
                    if (el.tagName != undefined && el.tagName != '')
                        len += el.tagName.length + 2
                    return { len: len, el: el }
                }
                el = el.previousSibling
            }
        },
        mouseDown(e) {
            const { clientX, clientY } = e
            this.downPos = {
                x: clientX,
                y: clientY,
            }
            this.content = this.data
        },
        mouseUp(e) {
            const { clientX, clientY } = e
            this.upPos = {
                x: clientX,
                y: clientY,
            }

            setTimeout(() => {
                const selection = window.getSelection().toString()
                this.getStartIndex(0, 0, selection.anchorNode)
                this.getEndIndex(0, 0, selection.anchorNode)
                this.selection = selection
                console.log(selection)
                if (selection.length > 0) {
                    this.popVisiable = true
                } else {
                    this.popVisiable = false
                }
            }, 10)
        },
        addNote() {
            console.log(1)
            let start =
                this.startIndex > this.endIndex
                    ? this.endIndex
                    : this.startIndex
            let end =
                this.startIndex > this.endIndex
                    ? this.startIndex
                    : this.endIndex
            this.$emit('notes', {
                id: this.id,
                selection: this.selection,
                start,
                end,
            })
        },
        addFeedback() {
            let start =
                this.startIndex > this.endIndex
                    ? this.endIndex
                    : this.startIndex
            let end =
                this.startIndex > this.endIndex
                    ? this.startIndex
                    : this.endIndex
            console.log(start, end)
            this.$emit('feedback', {
                id: this.id,
                selection: this.selection,
                start,
                end,
            })
        },
        closePop() {
            setTimeout(() => {
                this.popVisiable = false
            }, 200)
        },
        blur() {
            setTimeout(() => {
                this.closePop()
            }, 100)
        },
    },
    watch: {
        data(val) {
            this.content = JSON.parse(JSON.stringify(val))
        },
        keyword(keyword) {
            if (!keyword) {
                this.content = this.data
                return
            }
            this.content = this.data.replace(
                new RegExp(keyword, 'g'),
                "<span style='color:red;'>" + keyword + '</span>'
            )
        },
        selectItem(val) {
            if (val.CardId == this.id) {
                this.content = this.data
                this.$nextTick(() => {
                    let txt = $('#test' + this.id).html()
                    let { StartIndex, EndIndex, Txt, Color } = val
                    Color = Color || 'red'
                    let startStr = txt.substring(0, StartIndex)
                    let midStr = txt.substring(StartIndex, EndIndex)
                    let endStr = txt.substring(EndIndex, txt.length)
                    let temp = ''
                    let newStr = ''
                    let isTag = false
                    for (var i = 0; i < midStr.length; i++) {
                        // 遇到<
                        if (midStr[i] == '<') {
                            if (temp) {
                                newStr += `<span class="pos${val.Id}" style="background-color:${Color};">${temp}</span>`
                                temp = midStr[i]
                            } else {
                                temp = midStr[i]
                            }
                            isTag = true
                        } else if (midStr[i] == '>') {
                            temp += midStr[i]
                            newStr += temp
                            temp = ''
                            isTag = false
                        } else {
                            temp += midStr[i]
                        }
                        if (i == midStr.length - 1) {
                            if (isTag) {
                                newStr += temp
                            } else {
                                newStr += `<span class="pos${val.Id}" style="background-color:${Color};">${temp}</span>`
                            }
                        }
                    }
                    this.content = startStr + newStr + endStr
                })

                return
            } else {
                this.content = this.data
            }
        },
    },
    computed: {
        position() {
            let x = (this.upPos.x + this.downPos.x) / 2
            let y = Math.min(this.upPos.y, this.downPos.y) - 40
            return {
                x: x,
                y: y,
            }
        },
    },
    created() {
        this.content = this.data
    },
}
</script>
<style scoped lang="less">
:deep(.mce-edit-focus) {
    outline: none;
}

.editor {
    padding: 15px 20px 20px;
    font-size: 16px;
    white-space: normal;
    outline: none;
    position: relative;
    cursor: text;
    line-height: 22pt;

    * {
        font-family: 'Miscrosoft YaHei' !important;
    }
}

:deep(.mce-resizehandle) {
    display: none;
    border: 0 !important;
}

:deep(.mce-content-body) {
    overflow: auto;

    &::-webkit-scrollbar {
        border-radius: 3px;
        height: 6px;
        background-color: #e5e5e5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #f6a75c;
    }

    audio[data-mce-selected],
    embed[data-mce-selected],
    img[data-mce-selected],
    object[data-mce-selected],
    table[data-mce-selected],
    video[data-mce-selected] {
        outline: none;
    }

    img {
        // pointer-events: none;
    }
}

.popover_txt {
    position: fixed;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);

    :deep(.ant-btn) {
        padding: 0 10px;
        font-size: 14px;
        border: 0;
        border-right: 1px solid #00ffc0;
        background-color: #00ffc0;
        color: #333;

        &:last-of-type {
            border-right: 0;
            margin-left: 10px;
        }

        &:focus {
            color: rgba(0, 0, 0, 0.65);
        }

        &:hover {
            background-color: #eee;
            color: rgba(0, 0, 0, 0.65);
        }
    }

    :deep(.ant-popover-arrow) {
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%) rotate(45deg);
        border-color: transparent rgba(0, 0, 0, 0.65) rgba(0, 0, 0, 0.65) transparent;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    }
}

@media (max-width: 1024px) {
    .editor {
        font-size: 24px;
        line-height: 1.5;
    }

    .popover_txt {
        display: none !important;
    }
}
</style>
<style lang="less">
.editor {
    * {
        font-family: 'Miscrosoft YaHei' !important;
    }
}
</style>
